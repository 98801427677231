const getInstallationArray = (numberOrArray?: number | number[]): number[] => {
  if (!numberOrArray) {
    return [];
  }

  if (Array.isArray(numberOrArray)) {
    return numberOrArray;
  }
  return [numberOrArray];
};

export default getInstallationArray;
